.InteractActor-pane {
    margin-top: 6px;
}

.InteractActor-loading {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InteractActor-buttons {
    height: 48px;
    display: flex;
    justify-content: space-between;
}

.InteractActor-reply {
    margin-top: 18px;
}