@import "../../variables.scss";

.Actor-loading {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Actor-image {
    height: 52px;
    width: 52px;
    min-height: 52px;
    min-width: 52px;
    margin-right: 18px;
    border-radius: 10px;
    box-shadow: $box-shadow-center;
}

.Actor-collapsible {
    margin-top: 1rem;
}

.Actor-name-subtitle {
    margin-right: 1rem;
    overflow: hidden;
    overflow-wrap: break-word;
}

.Actor-name-small {
    margin-bottom: 8px;
}

.Actor-summary {
    overflow-wrap: break-word;

    a {
        color: var(--secondary-color);
    }
}

.Actor-collections {
    width: 100%;
}

// .Feed-tabs {
//     padding: 0px 12px;
// }

// .Feed-tab {
//     margin-top: 18px;
//     padding-bottom: 6px;
//     border-bottom: 1px solid var(--primary-color);
// }

// .Feed-tab a {
//     background-color: transparent;
//     color: var(--light-color);
//     margin-right: 12px;
//     margin-bottom: 12px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 8px;

//     svg {
//         height: 20px;
//         width: 20px;
//     }

//     @media only screen and (min-width: 768px) {
//         display: block;
//         padding: 6px 12px;
//     }
// }

// .Feed-tab a:hover {
//     color: var(--secondary-color);
// }

// .Feed-tab a.active {
//     background-color: var(--secondary-color) !important;
//     color: var(--dark-color) !important;
//     box-shadow: $box-shadow-center !important;
// }

// .Feed-tab a.active text {
//     font-weight: bold;
//     position: absolute;
// }

.tab-content {
    padding-top: 18px;
}

.tab-button-lg {
    display: none;
}
.tab-button-sm {
    display: initial;
}

@media only screen and (min-width: 768px) {
    .tab-button-lg {
        display: initial;
    }
    .tab-button-sm {
        display: none;
    }
}