$primary-color: #ff2e63; 
$secondary-color: #2EFFCA;
$tertiary-color: #8A0023;
$quaternary-color: #008A67;
$dark-color: #252a34;
$light-color: #e1e4ea;
$gray-color: #3f485a;
$tan-color: #dad5cb; 

$text-shadow-center: 0 4px 8px #00000033, 0 6px 20px #00000030;
$box-shadow-center: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
$box-shadow-top: 0 -4px 8px 0 #00000033, 0 -6px 20px 0 #00000030;