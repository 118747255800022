@import "../../variables.scss";


.Feed {
    display: flex;
    flex-direction: column;
    align-items: center;
}

// .Feed-welcome {
//     width: 100%;
// }

.Feed-collections {
    width: 100%;
}

.Feed-tab {
    padding-bottom: 6px;
    border-bottom: 1px solid var(--primary-color);
}

.Feed-tab a {
    background-color: transparent;
    color: var(--light-color);
    margin-right: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;

    svg {
        height: 20px;
        width: 20px;
    }

    @media only screen and (min-width: 768px) {
        display: block;
        padding: 6px 12px;
    }
}

.Feed-tab a:hover {
    color: var(--secondary-color);
}

.Feed-tab a.active {
    background-color: var(--secondary-color) !important;
    color: var(--dark-color) !important;
    box-shadow: $box-shadow-center !important;
}

// .Feed-tab a.active text {
//     font-weight: bold;
//     position: absolute;
// }

.tab-content {
    padding-top: 18px;
}

.tab-button-lg {
    display: none;
}
.tab-button-sm {
    display: initial;
}

@media only screen and (min-width: 768px) {
    .tab-button-lg {
        display: initial;
    }
    .tab-button-sm {
        display: none;
    }
}