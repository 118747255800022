@import '../../variables.scss';


.Activity-object {
    padding: 0px 12px;
}

.Activity-actor {
    margin-bottom: 1rem;
    display: flex;
}

.Activity-actor-image {
    height: 36px;
    width: 36px;
    min-height: 36px;
    min-width: 36px;
    margin-right: 18px;
    border-radius: 6px;
    box-shadow: $box-shadow-center;
}

.Activity-actor-name {
    font-size: 16px;
    margin-bottom: 6px;
}

.ActivityObject-reply {
    margin-top: 6px;
}
