.InteractObject {
    margin-top: 12px;
}

.InteractObject-pane {
    margin-top: 6px;
}

.InteractObject-loading {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.InteractObject-reply {
    margin-top: 18px;
}
